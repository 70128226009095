.quill {
}

/* Toolbar */

.quill .ql-toolbar {
  border: 1px solid #d1d6db;
  border-bottom: none;
}

/* Content Container */

.quill .ql-container {
  border: none;
}

/* Content Editor */

.quill .ql-editor {
  height: 150px;
  padding: 12px 16px;
  border: 1px solid #d1d6db;
  overflow-y: scroll;

  /* text-sm */
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
}

.quill .ql-editor:focus {
  border-color: var(--main-color);
}

/* Placeholder */
.quill .ql-editor::before {
  color: #d1d6db;
}

/* read-only */

.quill-readonly .ql-editor {
  height: fit-content;
  padding: 3px 0px;
  border: none;
}
